<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  name: 'Key',
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 27 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 10.8547H3.89417C3.66597 10.8547 3.44712 10.7641 3.28576 10.6027L1.25201 8.56899C0.915995 8.23297 0.915995 7.68818 1.25201 7.35216L3.28576 5.31842C3.44712 5.15706 3.66597 5.06641 3.89417 5.06641H11.0868"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      d="M13.5 5C14.2301 5 14.5828 4.77273 15 4C15.6258 2.84091 16.4356 1.625 18 1.625C19.035 1.625 20.15 1.625 22.2634 1.625C22.2987 1.625 22.334 1.62715 22.3691 1.63143C23.331 1.749 24.0421 2.28777 24.5024 3.24773C25.2064 4.71591 25.6757 5.41136 25.6757 7.34318C25.6757 9.275 24.9334 11.9283 24.5024 12.8295C24.0713 13.7307 23.4855 14.375 22.3122 14.375C21.53 14.375 19.9395 14.375 17.5407 14.375C17.0192 14.375 16.6021 14.1947 16.2892 13.8341C15.2529 12.6397 15 12 14.1772 10.5886"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle cx="20.0644" cy="8.06442" r="2.06442" :fill="accent" />
  </svg>
</template>
